<template>
  <div>
    <el-form
      v-loading="loading"
      :model="dataForm"
      :rules="rules"
      ref="dataForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item
        id="uid"
        label="游戏ID"
        prop="uid"
        style="margin-bottom: 35px"
      >
        <el-col :span="6">
          <el-input
            ref="uid"
            v-model="dataForm.uid"
            maxlength="8"
            placeholder="请输入游戏ID"
            show-word-limit
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item
        id="name"
        label="主播名称"
        prop="name"
        required
        style="margin-bottom: 35px"
      >
        <el-col :span="6">
          <el-input
            ref="name"
            v-model="dataForm.name"
            maxlength="10"
            placeholder="请输入主播名称"
            show-word-limit
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="主播头像" prop="avatar" id="avatar" required>
        <el-upload
          class="avatar-uploader"
          :show-file-list="false"
          :before-upload="beforeTopImageUpload"
          action="#"
          drag
          :http-request="httpUploadRequest"
        >
          <img v-if="dataForm.avatar" :src="dataForm.avatar" class="avatar" />
          <div v-else>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">拖到此处，或<em>点击上传</em></div>
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item
        id="sort"
        label="排名"
        prop="sort"
        required
        style="margin-bottom: 35px"
      >
        <el-col :span="6">
          <el-input
            ref="sort"
            type="number"
            v-model="dataForm.sort"
            maxlength="16"
            placeholder="请输入排名"
            show-word-limit
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item
        id="platform"
        label="直播平台"
        prop="platform"
        style="margin-bottom: 35px"
      >
        <el-col :span="6">
          <el-input
            ref="platform"
            v-model="dataForm.platform"
            maxlength="16"
            placeholder="请输入直播平台"
            show-word-limit
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item
        id="room"
        label="房间号"
        prop="room"
        style="margin-bottom: 35px"
      >
        <el-col :span="6">
          <el-input
            ref="room"
            v-model="dataForm.room"
            maxlength="20"
            placeholder="请输入房间号"
            show-word-limit
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item
        id="tags"
        label="标签"
        prop="tags"
        style="margin-bottom: 35px"
      >
        <el-tag
          :key="tag"
          v-for="tag in dataForm.tags"
          closable
          :disable-transitions="false"
          @close="handleClose(tag)"
        >
          {{ tag }}
        </el-tag>
        <el-input
          class="input-new-tag"
          v-if="inputVisible"
          v-model="inputValue"
          ref="saveTagInput"
          size="small"
          maxlength="4"
          @keyup.enter.native="handleInputConfirm"
          @blur="handleInputConfirm"
        >
        </el-input>
        <el-button
          v-else-if="dataForm.tags.length < 4"
          class="button-new-tag"
          size="small"
          @click="showInput"
          >+ New Tag</el-button
        >
        <div>
          <i style="font-size: 12px; color: gray"
            >最多可添加4个标签，每个标签2-4个字符</i
          >
        </div>
      </el-form-item>
      <el-form-item style="margin-top: 50px; text-align: center">
        <!--        <backButton></backButton>-->
        <perButton
          type="primary"
          perm="armory:anchor:save"
          @click="submitForm()"
          size="large"
          style="margin-left: 10px"
          >提交</perButton
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { uploadOssUrl } from "@/utils/uploadUrl";
import perButton from "@/components/perm/perButton";
import { randomStr } from "@/utils/randomStr";
import { mapActions } from "vuex";
export default {
  name: "sect_manager",
  components: {
    perButton,
  },
  props: {
    anchor_data: {
      type: Object,
    },
    view_type: {
      type: String,
    },
  },
  computed: {
    isEdit: function() {
      if (this.view_type == "edit") {
        return true;
      }

      return false;
    },
  },
  data() {
    return {
      type: "anchor",
      inputVisible: false,
      inputValue: "",
      dataForm: {
        _id: "",
        uid: "",
        name: "",
        avatar: "",
        sort: "",
        platform: "",
        room: "",
        tags: [],
      },
      rules: {
        uid: [
          {
            required: true,
            message: "请输入游戏ID",
            trigger: ["blur", "change"],
          },
          {
            min: 8,
            max: 8,
            message: "长度8个字符",
            trigger: ["blur", "change"],
          },
          { validator: this.idValidator, trigger: ["blur", "change"] },
        ],
        name: [
          {
            required: true,
            message: "请输入主播名称",
            trigger: ["blur", "change"],
          },
          {
            min: 1,
            max: 10,
            message: "长度在 1 到 10 个字符",
            trigger: ["blur", "change"],
          },
        ],
        avatar: [{ required: true, message: "请选择头像", trigger: "change" }],
        sort: [
          {
            required: true,
            message: "请输入排序",
            trigger: ["blur", "change"],
          },
          { validator: this.validatorInteger, trigger: ["blur", "change"] },
        ],
        platform: [
          {
            required: false,
            message: "请输入直播平台",
            trigger: ["blur", "change"],
          },
          {
            min: 1,
            max: 16,
            message: "长度在 1 到 16 个字符",
            trigger: ["blur", "change"],
          },
        ],
        room: [
          {
            required: false,
            message: "请输入房间号",
            trigger: ["blur", "change"],
          },
          {
            min: 1,
            max: 20,
            message: "长度在 1 到 20 个字符",
            trigger: ["blur", "change"],
          },
        ],
        tags: [
          {
            type: "array",
            required: false,
            message: "标签为数组",
            trigger: "change",
          },
        ],
      },
      loading: false,
    };
  },
  watch: {
    anchor_data: {
      handler: function(val) {
        console.log("111");
        this.setFormData();
      },
      immediate: true,
    },
  },
  async mounted() {
    this.setFormData();
  },
  methods: {
    idValidator(rule, value, callback) {
      if (/[^0-9,A-Z,a-z]/.test(value.trim())) {
        callback(new Error("只能包含数字和英文字母"));
      } else {
        callback();
      }
    },
    handleClose(tag) {
      this.dataForm.tags.splice(this.dataForm.tags.indexOf(tag), 1);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (
        inputValue &&
        (inputValue.trim().length < 2 || inputValue.trim().length > 4)
      ) {
        this.$message.warning("每个标签2-4个字符");
        return;
      }

      if (inputValue) {
        this.dataForm.tags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    validatorInteger(rule, value, callback) {
      if (!value.length || !value.trim().length) {
        callback();
        return;
      }

      if (isNaN(Number.parseInt(value))) {
        callback(new Error("请输入大于0的整数"));
      }

      if (Number.isInteger(Number(value)) && Number(value) > 0) {
        callback();
      } else {
        callback(new Error("请输入大于0的整数"));
      }
    },
    setFormData() {
      let data = this.anchor_data;
      if (data && data._id) {
        Object.keys(this.dataForm).forEach((key) => {
          if (data[key]) {
            this.dataForm[key] = data[key];
          }
        });

        if (data.tags) {
          this.dataForm.tags = data.tags.map((r) => r.trim());
        }
      } else {
        this.dataForm = {
          _id: "",
          uid: "",
          name: "",
          avatar: "",
          sort: "",
          platform: "",
          room: "",
          tags: [],
        };
      }
    },
    submitForm() {
      this.$refs.dataForm.validate(async (valid, invalidFields) => {
        if (valid) {
          let d = this.getSaveData();
          let { data, errorCode } = await this.$http.armory.save(this.type, d);
          if (errorCode != "0000") {
            this.$message.error("保存失败");
            return;
          }

          this.$message({
            type: "success",
            message: "保存成功",
          });

          this.removeTabByPath("/armory/anchor/add");
          this.removeTabByPath("/armory/anchor/edit");
          this.$router.push("/armory/anchor/index");
          this.$bus.$emit("onAnchorChanged", data);
        } else {
          console.log("error submit!!");
          console.log(invalidFields);
          let fileds = Object.keys(invalidFields);
          if (fileds.length) {
            let id1 = fileds[0];
            if (document.getElementById(id1)) {
              document
                .getElementById(id1)
                .scrollIntoView({ behavior: "smooth" });
            }

            let refKey = id1.replace(/\./g, "_");
            if (this.$refs[refKey]) {
              this.$refs[refKey].focus();
            }
          }
          return false;
        }
      });
    },
    getSaveData() {
      let data = {
        ...this.dataForm,
      };

      if (!data.tags || !data.tags.length) {
        delete data.tags;
      }

      return data;
    },
    resetForm() {
      this.$refs.dataForm.resetFields();
    },
    beforeTopImageUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG/PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }

      return isJPG && isLt2M;
    },
    async httpUploadRequest(item) {
      this.loading = true;
      let fileName = randomStr() + ".jpg";
      let path = uploadOssUrl().sect + fileName;
      let result = await this.$http.uploadFile(path, fileName, item.file);
      if (result) {
        this.dataForm.avatar = result;
      } else {
        this.$message.error("上传失败");
      }
      this.loading = false;
    },
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
};
</script>

<style scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
/deep/ .avatar {
  width: 178px;
  height: 178px;
  display: block;
}

/deep/ .el-upload-dragger {
  text-align: center;
  width: 178px;
  height: 178px;
}

/deep/ .el-upload-dragger img {
  margin: auto;
}
</style>
